import { Text } from '@/components/Text';
import { Button } from '@/components/buttons/Button';
import Image from 'next/image';

interface StaggeredContent {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  imageSrc: string;
  imageAlt: string;
  imageProps?: {
    width?: number;
    height?: number;
    maxWidth?: string;
    maxHeight?: string;
  };
}

interface StaggeredBlockProps extends StaggeredContent {
  imageOnRight: boolean;
}

interface StaggeredContentLayoutProps {
  sections: StaggeredContent[];
}

// Individual block component
function StaggeredBlock({ 
  title, 
  subtitle, 
  description, 
  buttonText, 
  buttonAction, 
  imageSrc, 
  imageAlt,
  imageProps,
  imageOnRight 
}: StaggeredBlockProps): JSX.Element {
  const TextContent = () => (
    <article className="flex flex-col space-y-6 md:w-1/2 px-2">
      <h2 className="sr-only">Feature Section</h2>
      <Text variant="h3" textColor="white" element="h3" className="break-words">
        {title}
      </Text>
      <Text variant="h4" textColor="white" className="opacity-70 break-words" element="h4">
        {subtitle}
      </Text>
      <Text variant="body-1" className="text-white/75 break-words" element="p">
        {description}
      </Text>
      <Button
        className="w-full md:w-auto"
        wrapperClass="bg-pink-gr group-active/button:bg-white-gr px-8 py-3"
        onClick={buttonAction}
      >
        <Text 
          className="transition-all duration-150 text-white group-active/button:text-black"
          variant="btn-1"
        >
          {buttonText}
        </Text>
      </Button>
    </article>
  );

  const ImageContent = () => (
    <figure className="md:w-1/2 flex justify-center items-center">
      <div className="relative overflow-hidden rounded-lg">
        <Image
          src={imageSrc}
          alt={imageAlt}
          width={imageProps?.width || 450}
          height={imageProps?.height || 350}
          className="rounded-lg object-cover"
          style={{
            maxWidth: imageProps?.maxWidth || '100%',
            maxHeight: imageProps?.maxHeight || 'auto',
            height: 'auto'
          }}
        />
      </div>
    </figure>
  );

  return (
    <section className="flex flex-col md:flex-row items-center justify-between gap-8 bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 shadow-lg overflow-hidden">
      {imageOnRight ? (
        <>
          <TextContent />
          <ImageContent />
        </>
      ) : (
        <>
          <ImageContent />
          <TextContent />
        </>
      )}
    </section>
  );
}

// Main layout component
export function StaggeredContentLayout({ sections }: StaggeredContentLayoutProps): JSX.Element {
  return (
    <div className="flex flex-col space-y-12 overflow-hidden">
      {sections.map((section, index) => (
        <StaggeredBlock
          key={index}
          {...section}
          imageOnRight={index % 2 === 0}
        />
      ))}
    </div>
  );
}