import React from 'react';
import { Text } from '@/components/Text';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { ICharacter } from '@/models/characters';
import { default_avatar } from '@/assets/remote-assets';
import { cn } from '@/lib/utils';
import { SkeletonImage } from '@/components/SkeletonImage';
import { personalities, Personality } from '@/components/character-creation/v2/creationData';
import { relationships, Relationship } from '@/components/character-creation/v2/creationData';
import { getPersonalityAndRelationship } from '@/components/character-creation/util';
import posthog from 'posthog-js';
import Link from 'next/link';

const PROBABILITY_OF_ROTATING_AVATARS = 0.2;

type Props = {
  character: ICharacter;
  className?: string;
  triggerAction: boolean;
  selected?: boolean;
  onCardClick?: (characterId: number) => void;
  nameOnly?: boolean;
  priority?: boolean;
};

export const CharacterCard: React.FC<Props> = ({ character, className, triggerAction, onCardClick, selected, nameOnly, priority }) => {
  const { push } = useRouter();
  const [avatarUrl, setAvatarUrl] = useState(character.avatar_urls && character.avatar_urls.length > 0 ? character.avatar_urls[0] : character.avatar_url || default_avatar);
  const [inRotation, setInRotation] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [personality, setPersonality] = useState<Personality | null>(null);
  const [relationship, setRelationship] = useState<Relationship | null>(null);

  useEffect(() => {
    if (!character.avatar_urls || character.avatar_urls.length <= 1) {
      setInRotation(true);
    } else {
      setInRotation(false);
    }
  }, []);

  useEffect(() => {
    if (character.created_by_user && character.description) {
      const { personality, relationship } = getPersonalityAndRelationship(character.creation_choices?.look_choices?.gender, character.creation_choices?.look_choices?.style, character.description);
      setPersonality(personality);
      setRelationship(relationship);
    }
  }, [character]);

  useEffect(() => {
    if (!inRotation) {
      // Randomly decide if it's going to rotate the avatars
      const random = Math.random();
      if (random < PROBABILITY_OF_ROTATING_AVATARS) {
        rotateAvatars(true);
      }
    }
  }, [triggerAction]);

  function handleCardClick(e: React.MouseEvent) {
    if (onCardClick) {
      e.preventDefault();
      onCardClick(character.id);
    } else {
      posthog?.capture('view_character_click', {character_id: character.id});
    }
  }

  const rotateAvatars = (anmating = false) => {
    // Change to a different avatar on hover
    if (character.avatar_urls) {
      // Find the first avatar that is not the same one as in current
      const currentAvatar = avatarUrl;
      const alternateAvatar = character.avatar_urls.find(url => url !== currentAvatar);

      if (anmating && alternateAvatar) {
        // Start the flip animation
        setIsAnimating(true);
        // Change to the alternate avatar in the middle or the animation
        setTimeout(() => {
          setAvatarUrl(alternateAvatar);
        }, 300);
        // Reset the animation state
        setTimeout(() => {
          setIsAnimating(false);
        }, 1000); // Duration of the animation
      } else {
        setAvatarUrl(alternateAvatar || currentAvatar);
      }
    }
  };

  const handleMouseEnter = () => {
    rotateAvatars();
    setInRotation(true);
  };

  const handleMouseLeave = () => {
    // Revert to the original avatar
    rotateAvatars();
    setInRotation(false);
  };

  // Create a descriptive alt text for better SEO
  const altText = `AI character ${character.name}${character.description ? ` - ${character.description.substring(0, 50)}${character.description.length > 50 ? '...' : ''}` : ''}`;

  const cardContent = (
    <>
      <div className="absolute left-[3px] top-[3px] h-full w-full bg-black-100 rounded-[15px] z-0" />
      <div className={cn("flex relative h-full max-h-full max-w-full z-1 overflow-hidden rounded-[inherit] border-2 border-black", 
                      "group-hover:border-pink left-[0] top-[0] group-active:left-[1px] group-active:top-[1px] transition-all duration-200", 
                      selected ? 'border-pink' : '')}>
        {priority ? (
          <SkeletonImage
              className={cn(
              'z-0 absolute left-0 top-0 w-full h-auto min-h-[400px] md:min-h-[400px] sm:min-h-[300px] min-h-[250px] pointer-events-none object-cover rounded-[10px]',
              isAnimating && 'flip-animation',
            )}
            height={400}
            width={300}
            sizes="(max-width: 768px) 50vw, 300px"
            src={avatarUrl}
            alt={altText}
            priority={priority}
          />
        ) : (
          <SkeletonImage
              className={cn(
              'z-0 absolute left-0 top-0 w-full h-auto min-h-[400px] md:min-h-[400px] sm:min-h-[300px] min-h-[250px] pointer-events-none object-cover rounded-[10px]',
              isAnimating && 'flip-animation',
            )}
            height={400}
            width={300}
            sizes="(max-width: 768px) 50vw, 300px"
            src={avatarUrl}
            alt={altText}
            loading="lazy"
          />
        )}

        <div className="absolute top-0 left-0 bg-card-gr w-full h-full" />
        <div className="w-full px-3 sm:pb-3 pb-2 z-10 relative flex flex-col flex-1 justify-end">
          <Text className="sm:mb-0.5 mb-0 sm:text-h3 text-h4" variant="h3" textColor="white" element="div">
            {character.name}
          </Text>

          {!nameOnly ? (
            character.created_by_user ? (
              <div className="flex flex-wrap gap-0.5 sm:gap-1 max-w-[calc(100%-1rem)]">
                {personality && (
                  <div className="bg-base-100 rounded-md sm:rounded-lg px-1.5 sm:px-2 py-0.5 sm:py-1 flex items-center z-10 w-max">
                    <span className="mr-0.5 sm:mr-1 flex-shrink-0 scale-60 sm:scale-75">{personality.asset}</span>
                    <Text variant="body-2" className="text-white whitespace-nowrap text-[10px] sm:text-xs">{personality.name}</Text>
                  </div>
                )}
                {relationship && (
                  <div className="bg-base-100 rounded-md sm:rounded-lg px-1.5 sm:px-2 py-0.5 sm:py-1 flex items-center z-10 w-max">
                    <span className="mr-0.5 sm:mr-1 flex-shrink-0 scale-60 sm:scale-75">{relationship.asset}</span>
                    <Text variant="body-2" className="text-white whitespace-nowrap text-[10px] sm:text-xs">{relationship.name}</Text>
                  </div>
                )}
              </div>
            ) : (
              <>
                {/* Visible description with line clamp for UI */}
                <Text
                  variant="body-1"
                  className="sm:line-clamp-2 line-clamp-4 min-h-7 transition-all duration-300 text-white/[.8] group-hover:line-clamp-none sm:text-base text-xs sm:leading-normal leading-tight"
                  element="p"
                >
                  {character.description}
                </Text>
                
                {/* Hidden full description for SEO - visually hidden but available to search engines */}
                <div className="sr-only">
                  <p>{character.description}</p>
                </div>
              </>
            )
          ) : null}
        </div>
      </div>
    </>
  );

  // If there's a custom click handler, wrap in a div, otherwise use a proper link
  if (onCardClick) {
    return (
      <div
        className={cn('group relative rounded-xl cursor-pointer h-[380px] lg:h-[380px] xl:h-[380px] md:h-[380px] sm:h-[300px] h-[250px]', className)}
        onClick={handleCardClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {cardContent}
      </div>
    );
  }

  return (
    <Link
      href={character.unique_code && character.seo_slug 
        ? `/character/${character.unique_code}/${character.seo_slug}`
        : `/character/${character.id}`}
      className={cn('group relative rounded-xl cursor-pointer block h-[380px] lg:h-[380px] xl:h-[380px] md:h-[380px] sm:h-[300px] h-[250px]', className)}
      onClick={handleCardClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {cardContent}
    </Link>
  );
};
