import React from 'react';
import { CharacterCard } from './CharacterCard';
import { cn } from '@/lib/utils';
import { ICharacter } from '@/models/characters';
import { useState, useEffect } from 'react';

// Define the interval duration as a constant
// 5 seconds, each card has their own chance of actually triggering the action
const ACTION_TRIGGER_INTERVAL = 5000;

type Props = {
  characters: ICharacter[];
  beforeCards?: React.ReactNode;
  afterCards?: React.ReactNode;
  onCardClick?: (characterId: number) => void;
  className?: string;
};

export const CharactersList: React.FC<Props> = ({ characters, beforeCards, afterCards, onCardClick, className }) => {
  const [triggerAction, setTriggerAction] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle the state to trigger action repeatedly
      setTriggerAction(prev => !prev);
    }, ACTION_TRIGGER_INTERVAL);

    return () => clearInterval(interval); // Cleanup the interval
  }, []);

  return (
    <section 
      aria-label="AI Characters Collection"
      className={cn(
        'grid h-fit w-full grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-[12px] gap-y-[8px] sm:gap-y-[12px]',
      )}
    >
      {/* Hidden heading removed to prevent showing in Ahrefs content analysis */}
      
      {beforeCards}
      
      {characters && characters.map((char, i) => (
        <article key={char.id} className={cn("character-card-wrapper", className)}>
          <CharacterCard 
            character={char} 
            triggerAction={triggerAction} 
            priority={i < 2} 
            onCardClick={onCardClick ? () => onCardClick(char.id) : undefined}
          />
        </article>
      ))}
      
      {afterCards}
    </section>
  );
};
