import { Text } from '@/components/Text';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import Head from 'next/head';

interface AccordionItem {
  id: string;
  question: string;
  answer: string;
}

interface AccordionListProps {
  content: {
    sectionTitle: string;
    sectionSubtitle?: string;
    items: AccordionItem[];
  }
}

function AccordionItem({ 
  id, 
  question, 
  answer,
  isOpen,
  onToggle 
}: AccordionItem & { 
  isOpen: boolean; 
  onToggle: (id: string) => void;
}): JSX.Element {
  return (
    <details className="group relative rounded-xl" open={isOpen}>
      <summary 
        className="relative z-1 overflow-hidden rounded-xl border-2 border-black hover:border-pink p-6 cursor-pointer list-none"
        onClick={(e) => {
          e.preventDefault();
          onToggle(id);
        }}
      >
        <div className="flex justify-between items-center">
          <Text variant="h4" textColor="white" element="h3">
            {question}
          </Text>
          <ChevronDown 
            className={`w-6 h-6 text-white transition-transform duration-200 ${
              isOpen ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
        </div>
      </summary>
      {isOpen && (
        <div className="p-6 pt-0">
          <Text variant="body-1" className="text-white/75 mt-4" element="p">
            {answer}
          </Text>
        </div>
      )}
    </details>
  );
}

export function AccordionList({ content }: AccordionListProps): JSX.Element {
  const { sectionTitle, sectionSubtitle, items } = content;
  const [openItem, setOpenItem] = useState<string | null>(null);

  const faqSchema: Record<string, unknown> = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": items.map((item: AccordionItem): Record<string, unknown> => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }))
  };

  const toggleItem = (id: string): void => {
    setOpenItem(openItem === id ? null : id);
  };

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }} key="faq-jsonld" />
      </Head>
      <section className="w-full flex flex-col items-center justify-center">
        <header className="text-center mb-12">
          <Text variant="h3" textColor="white" element="h2">
            {sectionTitle}
          </Text>
          {sectionSubtitle && (
            <Text variant="h4" textColor="white" className="opacity-70 mt-2" element="h3">
              {sectionSubtitle}
            </Text>
          )}
        </header>

        <div className="w-full max-w-6xl space-y-4">
          {items.map((item) => (
            <AccordionItem
              key={item.id}
              {...item}
              isOpen={openItem === item.id}
              onToggle={toggleItem}
            />
          ))}
        </div>
      </section>
    </>
  );
}