import React from 'react';
import ThotBotChatImage from '@/assets/icons/thot-bot-chat.svg';
import { Text } from '@/components/Text';
import { CustomLink } from '@/components/CustomLink';

export const Footer = () => {
  return (
    <footer className="bg-black-200 text-white mt-10 p-6 border-t border-gray">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex flex-col w-full md:w-1/3 justify-start">
          <ThotBotChatImage />
          <Text className="mt-2 w-full text-sm text-white/50" variant="body-2">
            Thotchat.ai is where you can create and meet AI girlfriends that feel alive. Join us today to get immersive AI photos and engage in uncensored AI roleplay.
          </Text>
        </div>
        <div className="flex flex-row w-full md:w-1/3 mt-8 md:mt-0 mr-10 justify-between">
            <div>
                <h3 className="text-md font-bold">Popular</h3>
                <ul className="text-sm text-white/50">
                    <li><CustomLink href="/info/best-hentai-app-alternative">Best Hentai App Alternative</CustomLink></li>
                    <li><CustomLink href="/info/free-ai-girlfriend-with-pictures">Free AI Girlfriend with Pictures</CustomLink></li>
                    <li><CustomLink href="/info/ai-girlfriend">AI Girlfriend Alternative</CustomLink></li>
                    <li><CustomLink href="/info/candy-ai">Better Than Candy.AI</CustomLink></li>
                    <li><CustomLink href="/info/x-rated-character-ai">NSFW Character.AI</CustomLink></li>
                    <li><CustomLink href="/info/ai-porn">AI Porn That Looks Good</CustomLink></li>
                    <li><CustomLink href="/info/hentai">Custom Hentai Waifus</CustomLink></li>
                    <li><CustomLink href="/info/ai-big-tits">AI Big Tits</CustomLink></li>
                </ul>
            </div>
            <div>
                <h3 className="text-md font-bold">Features</h3>
                <ul className="text-sm text-white/50">
                    <li><CustomLink href="/chat">NSFW AI chat</CustomLink></li>
                    <li><CustomLink href="/creations">Create AI character</CustomLink></li>
                    <li><CustomLink href="/images/generate">Generate AI images</CustomLink></li>
                    <li><CustomLink href="/images/collection">Gallery</CustomLink></li>
                    <li><CustomLink href="/images/feed">Image Feed</CustomLink></li>
                </ul>
            </div>
            <div>
                <h3 className="text-md font-bold">Resources</h3>
                <ul className="text-sm text-white/50">
                    <li>
                        <a href="https://discord.gg/thotbots" target="_blank" rel="noopener noreferrer">
                            Join our Discord
                        </a>
                    </li>
                    <li>
                        <CustomLink href="/legal/contact">
                            Contact us
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink href="/legal/cancellation-policy">
                            Cancellation Policy
                        </CustomLink>
                    </li>
                    <li><CustomLink href="/legal">Legal</CustomLink></li>
                    <li><CustomLink href="/sitemap">Sitemap</CustomLink></li>
                </ul>
            </div>
        </div>
        </div>
        <Text className="mt-6 md:mt-2 w-full text-sm text-white/50" variant="body-1">
            @copyright 2024 Thotchat.ai
        </Text>
    </footer>
  );
};